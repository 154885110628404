<template>
  <div class="column pt-0">
    <div class="has-background-white p-4">
      <h5 class="mb-4 has-text-weight-medium">ЗАРЕГИСТРИРОВАТЬ КОМПАНИЮ</h5>
      <form @submit.prevent="SendData">
        <p class="mb-3 has-text-grey">Введите данные</p>
        <b-field label="Тип организации">
          <b-select
            placeholder="Выберите филиал"
            v-model="type"
            required
          >
            <option
              v-for="(option, index) of list_of_type"
              :value="option"
              :key="index"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>

        <div class="control mt-4">
          <span>Наименование компании</span>
          <input
            class="input"
            type="text"
            v-model="company_name"
            placeholder="Наименование компании"
            required
          >
        </div>

        <div class="control mt-4">
          <span>БИН компании</span>
          <input
            class="input"
            type="number"
            v-model="bin"
            placeholder="БИН компании"
            required
          >
        </div>

        <button class="button is-info mt-5">Зарегистрировать</button>
      </form>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      list_of_type: ["ТОО", "АО", "ИП"],
      company_name: null,
      bin: null,
      type: null,
    };
  },
  methods: {
    async SendData() {
      let DATA = {
        "name": this.company_name,
        "bin": this.bin,
        "type": this.type,
      };

      try {
        const { data } = await this.$axios.post(`admin/companies`, DATA);
        this.$router.push("/company-list");
      } catch (e) {
        throw new Error(e);
      }
    },
  },
};
</script>

<style scoped>

</style>


